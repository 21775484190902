import React from 'react';
import '../styles/contacticon.css'

function ContactIcon({icon,text}) {
    return (
        <div className='icon-body'>
            <div className='icon'>
                <i class={icon}></i>
            </div>
            <div className='icon-txt'>
                <p>{text}</p>
            </div>
        </div>
    )
}

export default ContactIcon
