import React from 'react';
import '../styles/footer.css';
import Logo from '../images/logo.png';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-parent'>
      <div className='footer-container'>
        <div className='footer-logo-section'>
          <img className='logo-foot' src={Logo} alt='logo' />
          <p>Join Al Ghad Al Mushriq to boost your English and Arabic skills while diving deep into both languages.</p>
        </div>
        <div className='footer-link-section'>
          <p className='foot-section-title'>Links</p>
          <div className='footer-link-parent'>
            <Link className='foot-links'>About Us</Link>
            <Link className='foot-links'>Our Teachers</Link>
            <Link className='foot-links'>Terms and Conditions</Link>
            <Link className='foot-links'>Privacy and Policy</Link>
          </div>
        </div>
        <div className='footer-address-section'>
          <p className='foot-section-title'>Location</p>
          <p>Al Ghad Al Mushrq Education languages institute -sole proprietorship LLC</p>
          <p>ME9, Mohamed bin Zayed city, Abudhabi, UAE</p>
        </div>
        <div className='footer-social-section'>
          <p className='foot-section-title'>Social Media</p>
          <p className='social-section-desc'>Stay connected with us! Follow us on social media for the latest updates, news, and exclusive content</p>
          <div className='footer-social-icons'>
            <Link to='https://www.instagram.com/alghadalmushriqinstitute/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=='><i class="fa-brands fa-instagram"></i></Link>
            <Link to="https://www.facebook.com/people/Al-Ghad-Al-Mushirq-Institute/ "><i class="fa-brands fa-facebook"></i></Link>
          </div>
        </div>
      </div>
      <div className='credit-section'>
        <span>Designed by <Link to='https://alkatefitsolutions.com/' target='_blank' className='link_dec credit-link'>Al Katef IT Solutions</Link></span>
      </div>
    </div>
  )
}

export default Footer