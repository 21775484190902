// TeacherModal.js
import React from 'react';
import { Modal, Paper, Typography, Button } from '@mui/material';
import '../styles/teacher.css'

function TeacherModal({ open, onClose, teacher }) {
    if (!teacher) {
        return null;
    }

    const modalStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px',
        width: '80%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    const textStyle = {
        flex: 1,
        paddingLeft: '18px',
    };

    const paperStyle = {
        maxWidth: '600px',
        margin: '0 auto',
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Paper style={{ ...modalStyle, ...paperStyle }}>
                <div>
                    <img src={teacher.image} alt={teacher.name} style={{ width: '250px', height: '250px' }} />
                </div>
                <div style={textStyle}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }} gutterBottom>
                        {teacher.name}
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: '12px' }} gutterBottom>
                        {teacher.desc}
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: '12px', textAlign: 'right' }} gutterBottom>
                        {teacher.arabicDesc}
                    </Typography>
                    <div style={{ position: 'absolute', bottom: '10px', left: '70%', transform: 'translateX(-50%)' }}>
                        <Button variant="contained" onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </div>
            </Paper>
        </Modal>
    );
}

export default TeacherModal;
