import React, { useState } from 'react';
import CustomNavbar from '../components/Navbar';
import '../styles/about.css';
import PageTop from '../components/PageTop';
import AbtImg from '../images/abt-img.jpg';
import Testimonial from '../components/Testimonial';
import WhatsAppButton from '../components/Whatsapp';
import Footer from '../components/Footer';


function About() {

  // translations
  const translations = {
    'English': {
      // English text
      whoArewe: 'Who are we?',
      abtP1: 'We are institute that adopts the lasts educational methods and means for teaching and raising the level of the Arabic of the Arabic language among the Arabs as well as nonnative speakers, to develop communication skills as detailed in Holy Quran.',
      abtP2: 'As for English language and other language like German and French We establish it from the start for high level in language and equipping the learner to get degree in language. We have a cadre of trained specialists Arab and non-Arab.',
      abtP3: 'Our experience in the educational field extends for more than ten years in Qatar in center(Al Anwar)',
      abtP4: 'That was sufficiently attested and the quality of the educational level of its teachers and trainers and for this experience to institude “AL GHAD Al MUSHRQ”',
      abtP5: 'For languages and we added all modern educational Techniques with cadre that has post graduate qualifications in educational field higher experiences.',
    },

    'Arabic': {
      // Arabic text
      whoArewe: 'من نحن؟',
      abtP1: 'معهد الغد المشرق لتعليم اللغات نحن معهد يعتمد أحدث الطرق والوسائل التعليمية في تعليم ورفع مستوي اللغة العربية لدي العرب وكذلك الغير ناطقين بها لتطوير مهارات التواصل من قراءه وكتابه كما ورد في القراءان الكريم اما اللغة الإنجليزية واللغات الأخرى مثل الفرنسي والألماني نقوم بتأسيس فيها من البداية إلي اعلي مستويات اللغة وتجهيز المتعلم للحصول على الشهادات العلمية في اللغة.',
      abtP2: 'لدينا كادر من المتخصصين مدربات ومدربين عرب وغير العرب.',
      abtP3: 'تمتد خبراتنا بالمجال التعليمي لأكثر من عشر سنوات بدولة قطر بمركز الأنوار التعليمي والذي كان يشهد له بالكفاءة و جودة المستوي التعليمي لمدرساته ومدربيه ولهذه الخبرة الكبيرة بمجال التعليم قمنا بنقل كل خبراتنا لمعهد الغد المشرق لتعليم اللغات وأضافنا اليه كل وسائل التعليم الحديثة مع كادر حاصل علي مؤهلات علمية في مجال التعليم وذو خبرات عالية .',
      abtP4: '',
      abtP5: ''
    },
  };

  const [currentLanguage, setCurrentLanguage] = useState('English');

  const [language, setLanguage] = useState('Arabic');

  const toggleLanguage = () => {
    setCurrentLanguage(currentLanguage === 'English' ? 'Arabic' : 'English');
    setLanguage(language === 'Arabic' ? 'English' : 'Arabic');
  };

  return (
    <div>
      <div className='btn-container'>
        <button className='lang-toggle-btn' onClick={toggleLanguage}>{language}</button>
      </div>
      <CustomNavbar />
      <PageTop pageName="About Us" />
      <div className='about-section'>
        <div className={`about-txt ${currentLanguage === 'Arabic' ? 'rtl-text' : ''}`}>
          <h2>{translations[currentLanguage].whoArewe}</h2>
          <p>{translations[currentLanguage].abtP1}</p>

          <p>{translations[currentLanguage].abtP2}</p>

          <p>{translations[currentLanguage].abtP3}</p>

          <p>{translations[currentLanguage].abtP4}</p>

          <p>{translations[currentLanguage].abtP5}</p>
        </div>
        <div className='about-img'>
          <img src={AbtImg} alt='abt-img' />
        </div>
      </div>

      <div className='testimonial-parent'>
        <h4>Testimonial</h4>
        <div className='testimonial-section'>
          <Testimonial />
          <Testimonial />
          <Testimonial />
        </div>
      </div>
      <WhatsAppButton />
      <Footer />
    </div>
  )
}

export default About
