import React from 'react';
import '../styles/coursecard.css';

function CourseCard({icon,name,color}) {

    const iconStyle = {
        color: color, 
      };

  return (
    <div className='course-card'>
      <div className='course-card-inner'>
        <i class={icon} style={iconStyle}></i>
        <p>{name}</p>
      </div>  
    </div>
  )
}

export default CourseCard