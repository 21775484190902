// TeacherCard.js
import React from 'react';
import '../styles/teacher.css';

function TeacherCard({ teacher, showModal }) {
    return (
        <div className="teacher-card">
            <div className="teacher-card-inner">
                <img src={teacher.image} alt="" />
                <div className="teacher-details-parent">
                    <div className="teacher-card-details">
                        <p className="teacher-name">{teacher.name}</p>
                    </div>
                    <div className="teacher-btn-container">
                        <button onClick={() => showModal(teacher)}>View Details</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeacherCard;
