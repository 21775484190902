// Teacher.js
import React, { useState } from 'react';
import CustomNavbar from '../components/Navbar';
import '../styles/teacher.css';
import TeacherCard from '../components/TeacherCard';
import Footer from '../components/Footer';
import Dina from '../images/teachers/Miss_Dina.jpg';
import Amira from '../images/teachers/Miss_Amira.jpg';
import Nesma from '../images/teachers/Miss_Nesma.jpg';
import Fatma from '../images/teachers/Miss_Fatma .jpg';
import Manar from '../images/teachers/Miss_Manar.jpg';
import Nermin from '../images/teachers/Miss_Nermin.jpg';
import Menna from '../images/teachers/Miss_Menna.jpg';
import Huda from '../images/teachers/Miss_Hoda.jpg';
import TeacherModal from '../components/TeacherModal';
import WhatsAppButton from '../components/Whatsapp';

function Teachers() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  const handleShowModal = (teacher) => {
    setSelectedTeacher(teacher);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedTeacher(null);
    setOpenModal(false);
  };

  const teachersData = [
    { name: 'Ms. Dina', desc: 'English Instructor for all levels, Specialized in all scientific subject ,English, Math, and Science.Establishing the kids in English reading speaking – phonics Follow up on daily assignments activities. With the state, governmental, American, British and mini sterial curriculum.', arabicDesc:'مدرسة اللغة الإنجليزية لكل المراحل العلمية ومتخصصه في جميع المواد العلمية [انجليزي وماث وساينس ] تأسيس الأطفال في اللغة الإنجليزية قرأة وكتابة والصوتيات واستماع ومحادثة بالمنهج الحكومي والامريكي والبريطاني والوزاري', image: Dina },
    { name: 'Ms. Amira Shura', desc: 'Teacher of Math, physics for the high school students and teacher of maths and science for prep students. Establish all classes , Corses research operations.', arabicDesc:'مس اميرة الشورى     معلمة ماث وفيزياء للثانوي ومعلمة ماث وساينس للإعدادي ومعلمة جميع المواد للمرحلة الابتدائية وتأسيس جميع الصفوف وكورسات بحوث وإدارة الاعمال للجامعة', image: Amira },
    { name: 'Ms. Nesma Mohsin', desc: 'Teacher of biology , Math, Physics, and chemistry for the high school students and for teacher of maths and science for prep students.', arabicDesc:'مس نسمه محسن     معلمة بيولوجي وماث وفيزياء وكيمياء للثانوي ومعلمة ماث وساينس للإعدادي ومعلمة جميع المواد للمرحلة الابتدائية', image: Nesma },
    { name: 'Ms. Fatma', desc: 'English instructor for all levels and specialized in all scientific subject. Establishing the kids in English writing, reading, listening, speaking, phonics. Follow up on daily assignment and activities with the state, governmental, American, British and ministerial curriculum.', arabicDesc:'مدرب اللغة الإنجليزية لكل المراحل التعلمية ومتخصصة في جميع المواد العلمية [انجليزي -ماث- ساينس ]  تأسيس الأطفال في اللغة الإنجليزية والقراءة والاستماع والكتابة والصوتيات والمحادثة ] بالمنهج الحكومي والامريكي والبريطاني والوزاري.', image: Fatma },
    { name: 'Ms. Manar Anwar', desc: 'A teacher of establishing Arabic language and literary subject. Establishing children that are weak in reading, writing, and spelling. Follow up daily activites on the Alef platform.', arabicDesc:'الاستاذة منار أنور معلمة تأسيس اللغة العربية والمواد الأدبية تأسيس الأطفال وضعاف القراءة والكتابة والاملاء متابعة الوجبات اليومية على منصة الف', image: Manar },
    { name: 'Ms. Nermin Tariq', desc: 'Scientific subjects specialist, Math and science College math courses and following up the daily duties and activities.', arabicDesc:'مس نرمين طارق معلمة ماث ومواد علمية وكورسات جامعية والتأسيس المواد ومتابعة الواجبات اليومية المدرسية المساعدة في الواجبات على برنامج الف ', image: Nermin },
    { name: 'Ms. Menna Allah Anwar', desc: 'Holy Quran memorization teacher, Correction of the holy Quran recitation. Repetition with adjusting the intonation, biography in simplified ways.', arabicDesc:'الأستاذة منه الله أنور  معلمة تحفيظ قران كريم للأطفال والكبار والنساء  تصحيح التلاوة  والتكرار مع التجويد وتدريس العلوم الشرعية وشرح السيرة النبوية بطرق مبسطة', image: Menna },
    { name: 'Ms. Huda Al-Jundi', desc: 'Arabic language and literature teacher and establish all classes',arabicDesc:'الاستاذة هدى الجندي معلمة اللغة العربية وتأسيس جميع الصفوف وشرح المناهج والتدريب تحفيظ القران الكريم بالتجويد والتدقيق اللغوي', image: Huda },
  ];

  return (
    <div className="app">
      <CustomNavbar />
      <div className="teacher-container">
        <h3>OUR TEACHERS</h3>
        <div className="teachers">
          {teachersData.map((teacher, index) => (
            <TeacherCard
              key={index}
              teacher={teacher}
              showModal={() => handleShowModal(teacher)}
            />
          ))}
        </div>
      </div>

      <TeacherModal
        open={openModal}
        onClose={handleCloseModal}
        teacher={selectedTeacher}
      />
      <WhatsAppButton />
      <Footer />
    </div>
  );
}

export default Teachers;
