import React from 'react';
import '../styles/testimonial.css';
import Person from '../images/teachers/Miss_Dina.jpg';

function Testimonial() {
    return (
        <div className='testimonial-container'>
            <div className='testimonial-card'>
                <div className='testimonial-card-top'>
                    <div className='testimonial-details'>
                        <img className='testimonial-img' src={Person} alt='err' />
                        <div className='testimonial-details-name'>
                            <p className='testimonial-person-name'>Person Name</p>
                            <p className='testimonial-person-designation'>ABC Compnay, CEO</p>
                        </div>
                    </div>
                    <i class="fa-solid fa-quote-left fa-2x"></i>
                </div>
                <div className='testimonial-card-bottom'>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nam aliquam sem et tortor consequat id.</p>
                </div>
            </div>
        </div>
    )
}

export default Testimonial