import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../images/logo.png';
import '../styles/Navbar.css';
import { Link } from 'react-router-dom';

function CustomNavbar() {
  return (
    <Navbar expand="lg" className="navbar px-4">
      <Link to='/'>
        <Navbar.Brand>
          <img className='logo-nav' src={Logo} alt='logo' />
        </Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
        <Link className='nav-link' to="/">Home</Link>
          <Link className='nav-link' to="/About">About Us</Link>
          <Link className='nav-link' to="/Teachers">Teachers</Link>
          <Link to='/Contact'><Button variant="success" href="#contact" className="success-button">Contact Us</Button></Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustomNavbar;
