import React, { useState } from 'react';
import CustomNavbar from '../components/Navbar';
import PageTop from '../components/PageTop';
import '../styles/contact.css';
import ContactIcon from '../components/ContactIcon';
import emailjs from 'emailjs-com';
import WhatsAppButton from '../components/Whatsapp';
import Footer from '../components/Footer';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

// Handle form submission
const handleSubmit = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_jkdd21k', 'template_khfjb9m', e.target, 'N2DOLO1P0air0VrYR')
    .then((result) => {
      console.log('Email sent:', result.text);
      // Reset the form after submission
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    })
    .catch((error) => {
      console.error('Email sending failed:', error.text);
    });
};



  return (
    <div>
      <CustomNavbar />
      <PageTop pageName="Contact Us" />
      <div className='contact-us'>
      <div className='get-in-touch'>
          <div className='get-in-txt'>
            <h3>Get In Touch</h3>
            <p>Don't Hesitate to Contact</p>
          </div>
          <div className='contact-icons'>
            <ContactIcon icon='fa-solid fa-location-dot fa-2x' text='Abudhabi, UAE' />
            <ContactIcon icon='fa-solid fa-envelope-open-text fa-2x' text='al-ghad.uae123@outlook.com' />
            <ContactIcon icon='fa-solid fa-phone fa-2x' text='+971506483594' />
            <ContactIcon icon='fa-solid fa-tty fa-2x' text='025830603 (Telephone)' />
          </div>
        </div>
        <div className='form-map-section'>
          <div className='form-section'>
            <p>Feel free to get in touch with us. We value your feedback, questions, and inquiries. Thank you for reaching out to us!</p>
            <form onSubmit={handleSubmit}>
              <div className='mb-3'>
                <label htmlFor='name' className='form-label'>Name</label>
                <input
                  type='text'
                  className='form-control'
                  id='name'
                  name='name'
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='email' className='form-label'>Mail</label>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  name='email'
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='subject' className='form-label'>Subject</label>
                <input
                  type='text'
                  className='form-control'
                  id='subject'
                  name='subject'
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='message' className='form-label'>Message</label>
                <textarea
                  className='form-control'
                  id='message'
                  name='message'
                  value={formData.message}
                  onChange={handleInputChange}
                  rows='4'
                  required
                />
              </div>
              <button type='submit' className='btn send-btn'>Send Message</button>
            </form>
          </div>
          <div className='map-section'>
            <iframe title="Location of Al Ghad School" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3635.056820193589!2d54.5346842!3d24.3445133!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e473daf73ab17%3A0x12e8db72693df5db!2sAl%20Ghad%20Al%20Mushirq%20Institute!5e0!3m2!1sen!2sin!4v1698735310647!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <WhatsAppButton />
      <Footer />
    </div>
  )
}

export default Contact;
