import React from 'react';
import '../styles/pagetop.css';
import { Link } from 'react-router-dom';

function PageTop({pageName}) {
  return (
    <div className='page-top-bg'>
      <div className='page-top-txt'>
        <p className='page-top-title'>{pageName}</p>
        <div className='page-top-link-section'>
            <Link to='/' className='page-top-link'>Home</Link> <span className='slash'>/</span>
            <Link className='page-top-current'>{pageName}</Link>
        </div>
      </div>
    </div>
  )
}

export default PageTop
