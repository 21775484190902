import React, { useState, useEffect } from 'react';
import CustomNavbar from '../components/Navbar';
import TopBanner from '../images/WEB.jpg';
import CourseCard from '../components/CourseCard';
import '../styles/Home.css';
import ServiceImg from '../images/service-section-img.jpg';
import EngImg from '../images/english-service.jpg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import WhatsAppButton from '../components/Whatsapp';
import Footer from '../components/Footer';

function Home() {
  // slider settings
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  // State variable to track screen width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update the window width on window resize
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // translations
  const translations = {
    'English': {
      // English text
      courseOffers: 'OUR COURSE OFFERS',
      grammar: 'Grammar',
      spelling: 'Spelling',
      pronunciation: 'Pronunciation',
      vocabulary: 'Vocabulary',
      ourServices: 'OUR SERVICES',
      comprehensiveArabic: 'Comprehensive Arabic Language Program',
      comprehensiveEnglish: 'Comprehensive English Language Program',
      arabicpt1: 'Establishing Arabic reading, writing, and language skills',
      arabicpt2: 'Arabic lessons for all levels',
      arabicpt3: 'Teaching and simplifying grammar and morphology rules',
      arabicpt4: 'Teaching Arabic language skills',
      arabicpt5: 'Writing without spelling errors',
      arabicpt6: "Improving your kid's handwriting",
      arabicpt7: 'Teaching Arabic literature to all grades',
      arabicpt8: 'Strengthening reading and writing for all grades and explaining the prescribed curricula',
      engpt1: 'Quality education',
      engpt2: 'Creative learning',
      engpt3: 'Improving the English skills( speaking,  reading,  writing,  and listening)',
      engpt4: 'Increasing cognitive ability',
      engpt5: 'Improving communication skills',
      unlockLifelong: '"Unlock Lifelong English Fluency with AL GHAD Al MUSHRQ: Join Us Today!"',
      greatNews: 'Great news for all guardians ! Do you want to learn English and acquire it for lifetime?',
      joinUs: 'Join us at AL GHAD Al MUSHRQ if you really want to improve your accent English skills and learn a lot about English.',
      getStarted: 'Get Started',
    },

    'Arabic': {
      // Arabic text
      courseOffers: 'عروض الدورات لدينا',
      grammar: 'النحو ',
      spelling: 'الإملاء ',
      pronunciation: 'النطق ',
      vocabulary: 'المفردات',
      ourServices: 'خدماتنا',
      comprehensiveArabic: 'برنامج اللغة العربية الشامل',
      comprehensiveEnglish: 'برنامج اللغة الانجليزية الشامل',
      arabicpt1: 'تأسيس لغة عربية قراءة وكتابة ومهارات لغة',
      arabicpt2: 'دروس لغة عربية لجميع المراحل',
      arabicpt3: 'تدريس وتبسيط قواعد النحو والصرف',
      arabicpt4: 'تعليم مهارات اللغة العربية',
      arabicpt5: 'الكتابة دون اخطاء املائية',
      arabicpt6: 'تحسين الكتابة اليدوية لطفلك',
      arabicpt7: 'تدريس الادب العربى لكل المراحل',
      arabicpt8: 'تقوية القراءة والكتابة لجميع الصفوف وشرح المناهج الدراسية المقررة',
      engpt1: 'جودة التعليم',
      engpt2: 'التعلم الإبداعي',
      engpt3: 'تحسين مهارات اللغة الإنجليزية الأربع (التحدث، القراءة، الكتابة، الاستماع)',
      engpt4: 'زيادة القدرة المعرفية',
      engpt5: 'تحسين مهارات الاتصال',
      unlockLifelong: '"اكتشف طلاقة اللغة الإنجليزية مدى الحياة مع الغد المشرق: انضم إلينا اليوم!"',
      greatNews: 'نبأ عظيم لجميع الأوصياء! هل تريد تعلم اللغة الإنجليزية واكتسابها مدى الحياة؟',
      joinUs: 'انضم إلينا في الغدي المشرق إذا كنت تريد حقًا تحسين مهاراتك في اللغة الإنجليزية وتعلم الكثير عن اللغة الإنجليزية',
      getStarted: 'البدء',
    },
  };


  const [currentLanguage, setCurrentLanguage] = useState('English');

  const [language, setLanguage] = useState('Arabic');

  const toggleLanguage = () => {
    setCurrentLanguage(currentLanguage === 'English' ? 'Arabic' : 'English');
    setLanguage(language === 'Arabic' ? 'English' : 'Arabic');
  };


  return (
    <div>
      <div className='btn-container'>
        <button className='lang-toggle-btn' onClick={toggleLanguage}>
          {language}
        </button>
      </div>
      <CustomNavbar />

      <div className='top-container'>
        <img src={TopBanner} alt='banner' />
      </div>

      <div className='course-section'>
        <h4>{translations[currentLanguage].courseOffers}</h4>

        {/* screens less than 816px */}
        {windowWidth <= 816 ? (
          <div className='course-card-slider'>
            <Slider {...settings}>
              <div className='course-card-mob'>
                <CourseCard className='course-card-mob' name={translations[currentLanguage].grammar} icon='fa-solid fa-spell-check fa-2x' color='#007dac' />
              </div>
              <div className='course-card-mob'>
                <CourseCard className='course-card-mob' name={translations[currentLanguage].spelling} icon='fa-solid fa-pen-to-square fa-2x' color='#008e42' />
              </div>
              <div className='course-card-mob'>
                <CourseCard className='course-card-mob' name={translations[currentLanguage].pronunciation} icon='fa-solid fa-head-side-cough fa-2x' color='#d8b200' />
              </div>
              <div className='course-card-mob'>
                <CourseCard className='course-card-mob' name={translations[currentLanguage].vocabulary} icon='fa-solid fa-arrow-down-a-z fa-2x' color='#c9187e' />
              </div>
            </Slider>
          </div>
        ) : (
          <div className='course-card-section'>
            {/* screens wider than 816px */}
            <CourseCard name={translations[currentLanguage].grammar} icon='fa-solid fa-spell-check fa-2x' color='#007dac' />
            <CourseCard name={translations[currentLanguage].spelling} icon='fa-solid fa-pen-to-square fa-2x' color='#008e42' />
            <CourseCard name={translations[currentLanguage].pronunciation} icon='fa-solid fa-head-side-cough fa-2x' color='#d8b200' />
            <CourseCard name={translations[currentLanguage].vocabulary} icon='fa-solid fa-arrow-down-a-z fa-2x' color='#c9187e' />
          </div>
        )}
      </div>

      <div className='service-section-parent'>
        <div className='service-section'>
          <div className='service-section-txt'>
            <p className={`service-title ${currentLanguage === 'ar' ? 'rtl-text' : ''}`}>{translations[currentLanguage].ourServices}</p>
            {/* arabic course */}
            <h3 className={`${currentLanguage === 'ar' ? 'rtl-text' : ''}`}>{translations[currentLanguage].comprehensiveArabic}</h3>
            <div className={`service-p ${currentLanguage === 'ar' ? 'rtl-text' : ''}`}>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].arabicpt1}</p>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].arabicpt2}</p>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].arabicpt3}</p>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].arabicpt4}</p>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].arabicpt5}.</p>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].arabicpt6}</p>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].arabicpt7}</p>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].arabicpt8}</p>
            </div>
          </div>
          <div className='service-section-img'>
            <img src={ServiceImg} alt='service' />
          </div>
        </div>

        <div className='service-section service-section-english'>
          <div className='service-section-img'>
            <img src={EngImg} alt='service' />
          </div>
          <div className='service-section-txt'>
            {/* english course */}
            <h3 className={`${currentLanguage === 'ar' ? 'rtl-text' : ''}`}>{translations[currentLanguage].comprehensiveEnglish}</h3>
            <div className={`service-p ${currentLanguage === 'ar' ? 'rtl-text' : ''}`}>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].engpt1}</p>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].engpt2}</p>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].engpt3}</p>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].engpt4}</p>
              <p><i class={`fa-solid fa-circle-arrow-right service-point ${currentLanguage === 'ar' ? 'service-point-ar' : ''}`}></i> {translations[currentLanguage].engpt5}</p>
            </div>
          </div>
        </div>
      </div>

      <div className='join-us-section'>
        <h4>{translations[currentLanguage].unlockLifelong}</h4>
        <div className='join-us-p'>
          <p>{translations[currentLanguage].greatNews}</p>
          <p>{translations[currentLanguage].joinUs}</p>
          <button>{translations[currentLanguage].getStarted}</button>
        </div>
      </div>
      <WhatsAppButton />
      <Footer />
    </div>
  );
}

export default Home;
