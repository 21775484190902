import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import '../styles/whatsapp.css'

const whatsappURL = 'https://api.whatsapp.com/send?phone=+971561680255&text=Hello';

const WhatsAppButton = () => {
  const handleClick = () => {
    window.open(whatsappURL, '_blank');
  };

  return (
    <div
      className="whatsapp-button"
      onClick={handleClick}
    >
      <FaWhatsapp size={30} color="#fff" />
    </div>
  );
};

export default WhatsAppButton;
